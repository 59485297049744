import React from 'react';
import { Hearts } from 'react-loader-spinner';

function Loading() {
    return (
        <div style={{ display: 'flex', width: '100%', height: '80vh', justifyContent: 'center', alignItems: 'center' }}>
            <Hearts
                height="80"
                width="80"
                color="#f24570"
                ariaLabel="hearts-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
    );
}

export default Loading;
