import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
// import App from './App';
import { AuthProvider } from 'react-auth-kit';
import Loading from 'component/loading';

const App = lazy(() => import('./App'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <Suspense fallback={<Loading />}>
        <AuthProvider
            authType={'cookie'}
            authName={'_auth'}
            cookieDomain={window.location.hostname}
            cookieSecure={window.location.protocol === 'https:'}
        >
            <App />
        </AuthProvider>
    </Suspense>,
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
